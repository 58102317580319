import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询角色
export const usersSelect = data => request(`${XHR_URL}/api/v1/users`, data, "GET");
// 新增角色
export const usersInsert = data => request(`${XHR_URL}/api/v1/users`, data, "POST");
// 修改角色
export const usersUpdate = data => request(`${XHR_URL}/api/v1/users`, data, "PUT");
// 删除角色
export const usersDelete = data => request(`${XHR_URL}/api/v1/users`, data, "DELETE");
// 查询角色权限
export const userrolesSelect = data => request(`${XHR_URL}/api/v1/userroles`, data, "GET");
// 添加角色权限
export const userrolesInsert = data => request(`${XHR_URL}/api/v1/userroles`, data, "POST");