const regulars = {
    email: /^[A-Za-z0-9._%-]+@([A-Za-z0-9-]+.)+[A-Za-z]{2,4}$/, //邮箱
    phoneNumber: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))|119|110|120$/, //手机号码和固话
    userCard: /(^\d{8}(0\d|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/, //身份证号
    url: /^((https?|ftp|file):\/\/)?([\da-z.-]+).([a-z.]{2,6})(\/\w.-])\/?/, //url链接
    lowerCaseLetters: /^[a-z]+$/, //小写字母
    capital: /^[A-Z]+$/, //大写字母
    caseLetters: /^[A-Za-z]+$/, //大小写字母
    zipCode: /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/, //邮编
    bankCard: /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/, //银行卡
    tiNum: /^([1-9]{1})(\d{14}|\d{18}|\d{15})$/, //纳税人识别号
    blankSpace: /^\s+$/gi, //匹配全空格
    twelveTime: /^(1[0-2]|0?[1-9]):[0-5]\d:[0-5]\d$/, //12制时间（hh:mm:ss）
    // base64:/^\s data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&’,() +;=-._~:@\/?%\s]?)\s $/i, //base64
    chineseName: /^([\u4e00-\u9fa5·]{2,10})$/, //中文姓名
    licensePlateNumber: /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-HJ-NP-Z]{1}(([0-9]{5}[DF])|(DF[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领 A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9 挂学警港澳]{1})$/, //车牌号
    date: /^\d{4}(-)([0-1][0-2]|\d)\1([0-2]\d|\d|30|31)$/, //日期
    accountNumber: /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/, //帐号是否合法(字母开头，允许5-16字节，允许字母数字下划线组合
    allChineseCharacters: /^[\u4E00-\u9FA5]+$/, //纯中文/汉字
    allNumber: /^\d{1,}$/, //纯数字
    qqNumber: /^[1-9][0-9]{4,10}$/, //qq号码
    numberLetter: /^[A-Za-z0-9]+$/, //数字和字母
    allEnglish: /^[a-zA-Z]+$/, //纯英文
    allLetterEn: /^[a-z]+$/, //纯小写英文
    allCapitalizationEn: /^[A-Z]+$/, //纯大写英文
    password: /^.(?=.{6,})(?=.\d)(?=.[A-Z])(?=.[a-z])(?=.[!@#$%^&?]).*$/, //密码强度正则，最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符
    userName: /^[a-zA-Z0-9_-]{4,16}$/, //用户名正则，4到16位（字母，数字，下划线，减号
    ipv4: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, //ipv4地址正则
    wechatNumber: /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/, //微信号，6至20位，以字母开头，字母，数字，减号，下划线
    allCnNumber: /^(([\u4E00-\u9FA5])|(\d))+$/, //只包含中文和数字
    cnUserName: /^([\u4e00-\u9fa5]{2,20}|([a-zA-Z]+\s?){2,20})$/, //中文姓名正则
    lon: /^[\-\+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,5}|180\.0{1,6})$/, //经度
    lat: /^[\-\+]?([0-8]?\d{1}\.\d{1,5}|90\.0{1,6})$/, //纬度
    lonlat: /^[\-\+]?(0?\d{1,2}\.\d{1,5}|1[0-7]?\d{1}\.\d{1,6}|180\.0{1,6})[,]{1}[\-\+]?([0-8]?\d{1}\.\d{1,6}|90\.0{1,6})$/,
    priceNumber:/^(([1-9]\d*)|([0-9]\d*\.\d?[1-9]{1}))$/
};

export default regulars;
