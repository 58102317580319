<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<el-select v-model="status" placeholder="请选择账号状态" clearable class='inputs'>
					<el-option label="正常" value="Valid"> </el-option>
					<el-option label="禁用" value="Invalid"> </el-option>
				</el-select>
				<el-input v-model="word" placeholder="请输入账号名称" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
			<div class="m_tools_content">
				<el-button @click="showInsert" class="m_add m_btn_common">添加</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<el-table :data="tableData" stripe>
				<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="userCode" label="账号" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="userName" label="名字" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="avatar" label="头像" show-overflow-tooltip>
					<template slot-scope="scope">
						<a :href="fileUrl + scope.row.avatar" target="_blank">查看</a>
					</template>
				</el-table-column>
				<el-table-column prop="dept" label="部门" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="mobile" label="手机" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="操作" width="130" align="center">
					<template slot-scope="scope">
						<i class="el-icon-edit-outline m_edit" @click="showUpdate(scope.row)" title="修改"></i>
						<i class="el-icon-circle-close m_edit" @click="userInvalid(scope.row)" style="color:#ff0000;" title="禁用" v-if="scope.row.status === 'Valid'"></i>
						<i class="el-icon-circle-check m_edit" @click="userValid(scope.row)" title="启用" v-else></i>
						
						<i class="el-icon-setting m_edit" @click="showMenuConfig(scope.row.userId)" title="配置权限"></i>
						<i class="el-icon-key m_edit" @click="showPwd(scope.row.userId)" title="更改密码"></i>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title="dialogTitle" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="80px" :rules="rules" style="padding-right: 10px">
					<el-form-item label="头像" prop="avatar" style="text-align: center">
						<el-upload class="avatar-uploader" :show-file-list="false" :action="fileUpLoadUrl" :headers="{ token: token }" :file-list="pictureList" :on-success="upLoadSuccess">
							<img v-if="form.avatar" :src="fileUrl + form.avatar" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="账号" prop="userCode">
						<el-input v-model="form.userCode"></el-input>
					</el-form-item>
					<el-form-item label="名字" prop="userName">
						<el-input v-model="form.userName"></el-input>
					</el-form-item>
					<el-form-item label="部门" prop="dept">
						<el-input v-model="form.dept"></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="tel">
						<el-input v-model="form.tel"></el-input>
					</el-form-item>
					<el-form-item label="联系手机" prop="mobile">
						<el-input v-model="form.mobile"></el-input>
					</el-form-item>
					<el-form-item label="等级" prop="userLevel">
						<el-select v-model="form.userLevel" placeholder="请选择等级" :popper-append-to-body="false">
							<el-option label="普通" :value="1"> </el-option>
							<el-option label="管理员" :value="2"> </el-option>
							<el-option label="超级账号" :value="3"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注" prop="note">
						<el-input v-model="form.note"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="save" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="cancel" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="配置权限" :visible.sync="menuDialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-tree :data="treeData" :props="treeProps" ref="tree" node-key="roleId" highlight-current show-checkbox :expand-on-click-node="false">
				</el-tree>
			</div>
			<div class="m_dialog_bottom" v-if="treeData.length >0">
				<el-button type="primary" @click="saveMenuConfig" size="mini" class="m_btn_save">保存</el-button>
			</div>
		</el-dialog>
		<el-dialog :close-on-click-modal="false" title="修改密码" :visible.sync="pwdDialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="pwdDialogTopStyle">
				<el-form ref="pwdForm" :model="pwdForm" label-width="100px" :rules="pwdRules" style="padding-right: 10px">
					<el-form-item label="老密码" prop="oldpwd">
						<el-input v-model="pwdForm.oldpwd"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newpwd">
						<el-input v-model="pwdForm.newpwd"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" @click="savePwd" size="mini" class="m_btn_save">保存</el-button>
				<el-button @click="pwdDialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import regulars from "@/utils/regular";
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE } from "@/utils/config";
	import {
		usersSelect,
		usersInsert,
		usersUpdate,
		usersDelete,
		userrolesSelect,
		userrolesInsert,
	} from "@/api/user";
	import { rolesSelect } from "@/api/roles";

	import { fileUpLoadUrl,editPassWord } from "@/api/base.js";
	import { XHR_URL } from "@/utils/config";
	export default {
		directives: {
			elDragDialog,
		},
		data() {
			return {
				total: 0,
				currentPage: 1,
				selectedId: null,
				word: null,
				status:null,
				pictureList: [],
				tableData: [],
				treeData: [],
				treeProps: {
					children: "children",
					label: "roleName",
				},
				dialogTitle: "添加",
				dialogVisible: false,
				menuDialogVisible: false,
				rules: {
					userCode: [{
							required: true,
							message: "请输入用户账号",
							trigger: "blur"
						},
						{
							min: 1,
							max: 64,
							message: "用户账号过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 64,
							message: "用户账号过长",
							trigger: "blur"
						},
					],
					userName: [{
							required: true,
							message: "请输入用户名称",
							trigger: "blur"
						},
						{
							min: 1,
							max: 255,
							message: "用户名称内容过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "用户名称内容过长",
							trigger: "blur"
						},
					],
					avatar: [{
							required: true,
							message: "头像不能为空",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "头像过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "头像过长",
							trigger: "blur"
						},
					],
					userLevel: [{
						required: true,
						message: "请选择等级",
						trigger: "change"
					}, ],
					dept:[{
						required: true,
						message: "内容不能为空",
						trigger: "change"
						}
					],
					tel:[{
						required: true,
						message: "内容不能为空",
						trigger: "change"
						},
						{
							pattern:regulars.telNumber,
							message: "电话格式错误"
						}
					],
					mobile:[{
						required: true,
						message: "内容不能为空",
						trigger: "change"
					},{
						pattern:regulars.phoneNumber,
						message: "手机格式错误"
					}],
					note: [{
							required: true,
							message: "请输入账号备注说明",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "账号备注内容过长",
							trigger: "change"
						},
						{
							min: 1,
							max: 255,
							message: "账号备注内容过长",
							trigger: "blur"
						},
					],
				},
				form: {
					userId: 0,
					userCode: "",
					userName: "",
					userLevel: "",
					avatar: "",
					note: "",
					tel:"",
					mobile:"",
					dept:"",
					status:"Valid"
				},
				pwdDialogVisible: false,
				pwdForm: {
					userId: 0,
					oldpwd: '',
					newpwd: ''
				},
				pwdRules: {
					newpwd: [{
						required: true,
						message: "内容不能为空",
						trigger: "blur"
					}]
				},
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
			token() {
				return this.$store.getters.token;
			},
			fileUpLoadUrl() {
				return fileUpLoadUrl;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height: '400px'
				};
			},
			pwdDialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height: '200px'
				};
			}
		},
		components: {
			mPagination,
		},
		created() {
			this.select();
			this.rolesSelect();
		},
		mounted() {},
		methods: {
			upLoadSuccess(response) {
				console.log(response);
				this.pictureList = [];
				this.form.avatar = response.results[0].url;
			},
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			showInsert() {
				this.initForm();
				this.dialogTitle = "添加";
				this.dialogVisible = true;
			},
			showUpdate(data) {
				this.initForm();
				this.dialogTitle = "修改";
				this.form = data;
				this.dialogVisible = true;
			},
			showMenuConfig(id) {
				if(this.$refs.tree) {
					this.$refs.tree.setCheckedKeys([]);
				}

				this.menuDialogVisible = true;
				this.selectedId = id;
				this.userrolesSelect(id);
			},
			showPwd(id) {
				this.pwdForm.userId = id;
				this.pwdDialogVisible = true;
			},
			savePwd() {
				this.$refs.pwdForm.validate((valid) => {
					if(valid) {
						this.updatePwd();
					} else {
						return false;
					}
				});
			},
			initForm() {
				if(this.$refs.form) {
					this.$refs.form.resetFields();
				}
				this.form = {
					userId: 0,
					userCode: "",
					userName: "",
					userLevel: "",
					avatar: "",
					note: "",
				};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						switch(this.dialogTitle) {
							case "添加":
								this.insert();
								break;
							case "修改":
								this.update();
								break;
						}
					} else {
						return false;
					}
				});
			},
			saveMenuConfig() {
				const result = this.$refs.tree.getCheckedNodes();
				let list = [];
				result.forEach((item) => {
					list.push({
						roleId: item.roleId,
						enabled: true,
					});
				});
				this.userrolesInsert({
					userId: this.selectedId,
					userRoles: list,
				});
			},
			cancel() {
				this.select();
				this.dialogVisible = false
			},
			userInvalid(data){
				data.status ='Invalid';
				this.form = data;
				this.update();
			},
			userValid(data){
				data.status ='Valid';
				this.form = data;
				this.update();
			},
			async select() {
				const res = await usersSelect({
					userName: this.word,
					status:this.status,
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async insert() {
				const res = await usersInsert(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "添加成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async update() {
				const res = await usersUpdate(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
			async updatePwd() {
				const res = await editPassWord(this.pwdForm);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.pwdDialogVisible = false;
			},
			async delete() {
				const res = await usersDelete();
				const {
					pageCount,
					results
				} = res;
			},
			async rolesSelect() {
				const res = await rolesSelect({});
				const {
					pageCount,
					results
				} = res;
				this.treeData = results;
			},
			async userrolesSelect(data) {
				const res = await userrolesSelect({
					userId: data,
				});
				const {
					pageCount,
					results
				} = res;
				let list = [];
				results.forEach((item) => {
					list.push(item.role.roleId);
				});
				this.$refs.tree.setCheckedKeys(list);
			},
			async userrolesInsert(data) {
				const res = await userrolesInsert(data);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "添加成功",
					type: "success",
				});
				this.menuDialogVisible = false;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>