import request from "@/utils/request";
import { XHR_URL } from "@/utils/config"

// 查询角色
export const rolesSelect = data => request(`${XHR_URL}/api/v1/roles`, data, "GET");
// 新增角色
export const rolesInsert = data => request(`${XHR_URL}/api/v1/roles`, data, "POST");
// 修改角色
export const rolesUpdate = data => request(`${XHR_URL}/api/v1/roles`, data, "PUT");
// 删除角色
export const rolesDelete = data => request(`${XHR_URL}/api/v1/roles`, data, "DELETE");
// 查询角色权限
export const roleprivilegesSelect = data => request(`${XHR_URL}/api/v1/roleprivileges`, data, "GET");
// 添加角色权限
export const roleprivilegesInsert = data => request(`${XHR_URL}/api/v1/roleprivileges`, data, "POST");